const gameData = [
  {
    id: 'sugarpuffs',
    name: 'SUGAR PUFF RUSH',
    description: 'Description for SUGAR game',
    url: 'https://advance-genre-425305-g1.web.app/sugarpuff/', // Adjust the path according to your structure
    price: 'Free', // Example field
    releaseDate: '2022-01-01', // Example field
    width: '1920px',
    height: '1080px',
    transform: 'scale(0.6)',
  },
  {
    id: 'idlemarkets',
    name: 'IDLE MARKET',
    description: 'Description for IDLE game',
    url: 'https://advance-genre-425305-g1.web.app/idlemarket/',
    price: 'Free',
    releaseDate: '2021-06-15',
    width: '1920px',
    height: '1080px',
    transform: 'scale(0.6)',
  },
  {
    id: 'jetpacks',
    name: 'JETWING RIDE',
    description: 'Description for JET PACK game',
    url: 'https://advance-genre-425305-g1.web.app/jetpack/',
    price: '$10',
    releaseDate: '2019-08-20',
    width: '1280px',
    height: '720px',
    transform: 'scale(0.8)',
  },
  {
    id: 'bitcoinclickers',
    name: 'BITCOIN MILLIONAIRE',
    description: 'Description for BITCOIN CLICKER game',
    url: 'https://advance-genre-425305-g1.web.app/bitcoinclicker/',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'warriorvsevil',
    name: 'Warrior vs Evil',
    description: 'Description for Warrior vs Evil game',
    url: 'https://advance-genre-425305-g1.web.app/warriorvsevil/',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1920px',
    height: '1080px',
    transform: 'scale(0.6)',
  },
  {
    id: 'ninjafruit',
    name: 'Ninja Fruit',
    description: 'Description for Ninja Fruit game',
    url: 'https://advance-genre-425305-g1.web.app/ninjafruit/',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'minicar',
    name: 'Mini Car',
    description: 'Description for Mini Car game',
    url: 'https://advance-genre-425305-g1.web.app/car-race/',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'planettanks',
    name: 'Planet Tanks',
    description: 'Description for Ninja Fruit game',
    url: 'https://advance-genre-425305-g1.web.app/tank/',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'roadfury',
    name: 'Road Fury',
    description: 'Description for Road Fury',
    url: 'https://advance-genre-425305-g1.web.app/roadfury/',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'zombiedefense',
    name: 'Zombie Defense',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/zombiedefense/',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'zoorun',
    name: 'Zoo Run',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/zoorun/',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'arrow',
    name: 'Arrow',
    description: 'Description for Arrow',
    url: 'https://advance-genre-425305-g1.web.app/Arrows',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'flapcat',
    name: 'Flap Cat',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/Flapcat',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'inorbit',
    name: 'Inorbit',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/InOrbit',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'jumpmania',
    name: 'Jump Mania',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/JumpMania',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'zigzag',
    name: 'Zig Zag',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/ZigZag',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'driverhighway',
    name: 'Driver Highway',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/DriverHighway',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'infinitejumper',
    name: 'Infinite Jumper',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/InfiniteJumper',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'animalcrush',
    name: 'Animal Crush',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/AnimalCrush',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'pacrush',
    name: 'Pac Rush',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/PacRush',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'colorup',
    name: 'Color Up',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/PacRush',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: '2048',
    name: '2048',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/PacRush',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'basketball',
    name: 'Basket Ball',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/PacRush',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'stackbuilder',
    name: 'Stack Builder',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/PacRush',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
  {
    id: 'stackingcolor',
    name: 'Stacking Color',
    description: 'Description for Zombie Defense',
    url: 'https://advance-genre-425305-g1.web.app/PacRush',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
];

export default gameData;
