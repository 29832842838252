import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import API from "../../../api"; // Adjust the path to your API utility
import { isAuthenticated } from "../../../utils/auth";
import Slider from "react-slick"; // Adjust the path to your auth utility
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const gameNames = [
  "Mobile Legends",
  "Free Fire",
  "Honor of Kings",
  "Valorant",
  "PUBG Mobile (Indonesia)",
  "Call of Duty Mobile (Bind Garena)",
  "Genshin Impact",
  "Age of Empires Mobile",
];

const gameImages = {
  "Mobile Legends": "https://storage.googleapis.com/nero-assets/home/MOBA.webp",
  "Free Fire": "https://storage.googleapis.com/nero-assets/home/FREEFIRE.webp",
  "Honor of Kings": "https://storage.googleapis.com/nero-assets/home/HOK.webp",
  Valorant: "https://storage.googleapis.com/nero-assets/home/VALO.webp",
  "PUBG Mobile (Indonesia)":
    "https://storage.googleapis.com/nero-assets/home/PUBG.webp",
  "Call of Duty Mobile (Bind Garena)":
    "https://storage.googleapis.com/nero-assets/home/CODM.webp",
  "Genshin Impact":
    "https://storage.googleapis.com/nero-assets/home/GENSHIN.webp",
  "Age of Empires Mobile":
    "https://storage.googleapis.com/nero-assets/home/AOE.webp",
};

const GamesArea = () => {
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)
    let slider1,slider2;
  
  
    useEffect(function(){
      setNav1(slider1)
      setNav2(slider2)
    },[slider1,slider2])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get("retrieveDTU");
        console.log("API Response in GamesArea Component:", response);
        if (response.data.response.status === "200") {
          const filteredProducts = response.data.response.list_dtu.filter(
            (product) => gameNames.includes(product.name)
          );
          console.log("Filtered Products:", filteredProducts);
          setProducts(filteredProducts);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchData();
  }, []);

  const handleLinkClick = (event, productName) => {
    history.push(`/product/${productName}`);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    touchMove: true,
    draggable: true,
    swipeToSlide: true,
    touchThreshold: 10,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="featured-game-area position-relative pt-115 pb-90">
      <div className="featured-game-bg" />

      <div className="block-black"></div>
      <div className="container">
        <div className="Grid-parent">
          <div className="Slider-grid z-index-1">
            <Slider {...settings}>
              <div>
                <Link to="/top-up">
                  <img
                    className="banner-home"
                    src="https://storage.googleapis.com/nero-assets/promo-banner/promo-small-jan-2025.png"
                    alt="Nero Promo Tahun Baru"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </Link>
              </div>
              <div>
                <Link to="/top-up">
                  <img
                    className="banner-home"
                    src="https://storage.googleapis.com/nero-assets/promo-banner/christmas-promo-2024.jpg"
                    alt="Nero Promo Desember Natal"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </Link>
              </div>
              <div>
                <Link to="/top-up">
                  <img
                    className="banner-home"
                    src="https://storage.googleapis.com/nero-assets/promo-banner/nero-promo-des-1212-2024.jpg"
                    alt="Nero Promo Desember 12.12"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </Link>
              </div>
              <div>
                <Link to="/top-up">
                  <img
                    className="banner-home"
                    src="https://storage.googleapis.com/nero-assets/promo-banner/nero-promo-desember.jpg"
                    alt="Nero Promo Desember"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </Link>
              </div>
              <div>
                <Link to="/top-up">
                  <img
                    className="banner-home"
                    src="https://storage.googleapis.com/nero-assets/promo-banner/nero-promo-november.jpg"
                    alt="Nero Promo November"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </Link>
              </div>
              <div>
                <Link to="/top-up">
                  <img
                    className="banner-home"
                    src="https://storage.googleapis.com/nero-assets/promo-banner/nero-games-promo-oktober.jpg"
                    alt="Nero Promo October"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </Link>
              </div>

              <div>
                <Link to="/top-up">
                  <img
                    className="banner-home"
                    src="https://storage.googleapis.com/nero-assets/promo-banner/nero-promo-kemerdekaan-2024.jpg"
                    alt="Nero Promo September"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </Link>
              </div>
              <div>
                <Link to="/top-up">
                  <img
                    className="banner-home"
                    src="https://storage.googleapis.com/nero-assets/promo-banner/nero-promo-september.jpg"
                    alt="Nero Promo September"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                </Link>
              </div>
            </Slider>
          </div>
          <div className="image-grid-1 z-index-1">
            <Link className="link-for" to="/product/Mobile Legends">
              <img
                src="https://storage.googleapis.com/nero-assets/promo-banner/nero-news-banner-1.jpg"
                alt="Mini Banner MLBB 1"
              />
            </Link>
          </div>
          <div className="image-grid-2 z-index-1">
            <Link className="link-for" to="/product/Mobile Legends">
              <img
                src="https://storage.googleapis.com/nero-assets/promo-banner/nero-news-banner-2.jpg"
                alt="Mini Banner MLBB 2"
              />
            </Link>
          </div>
          <div className="image-grid-3 z-index-1">
            <Link className="link-for" to="/product/Free Fire">
              <img
                src="https://storage.googleapis.com/nero-assets/promo-banner/nero-news-banner-3.jpg"
                alt="Banner Free Fire 1"
              />
            </Link>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8 w100">
            <div className="section-title text-center m-50 home-content">
              <h2 className="h2-home text-black">
                TOP UP MOBILE GAMES POPULER
              </h2>
              <p className="p-home">
                Temukan game favoritemu dengan harga terbaik
              </p>
            </div>
          </div>
        </div>

        <div className="row featured-active justify-center">
          {gameNames.map((name, index) => (
            <div
              key={index}
              className="col-lg-4 col-sm-6 grid-item home-card-width"
            >
              <div className="featured-game-item mb-30">
                <div className="featured-game-thumb">
                  <img src={gameImages[name]} alt={name || "Game Image"} />
                </div>
                <div className="featured-game-content featured-game-overlay-content">
                  <h4>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/product/${name}`}
                      onClick={(event) => handleLinkClick(event, name)}
                    >
                      <span style={{ color: "white" }}>Top Up</span>
                      <span
                        style={{
                          color: "yellow",
                          fontWeight: "bold",
                          display: "block",
                          marginTop: "10px",
                        }}
                      >
                        Sekarang
                      </span>
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <section className="released-games-area pt-115 pb-70">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <div className="section-title text-center mb-20">
                    <h2 className="h2-home text-black">Selamat datang di Nero Games!</h2>
                  </div>
                </div>
              </div>
              <div className="row align-items-center padding-30 margin-100">
                <div className="col-xl-8 col-lg-12 max-height-unset">
                  <Slider className="released-game-active "
                  asNavFor={nav2}
                  ref={slider => (slider1 = slider)}
                  arrows={false}
                  >
                    <div className="released-game-carousel">
                      <div className="released-game-item">
                        <div className="released-game-img">
                          <img className='released-img' style={{width:"100%"}} src="https://storage.googleapis.com/nero-assets/home/cnd-med-banner.webp" alt="" />
                        </div>
                        <div className="released-game-content">
                          <h4>SugarPuff <span>Rush</span></h4>
                          <div className="released-game-list mb-15">
                            <ul className="ul-design">
                              <li><span>Category</span>Puzzle Game</li>
                              <li><span>Model</span>Puzzle Match Game</li>
                              <li><span>Controller</span>Web Base Game</li>
                            </ul>
                          </div>
                          <Link to="/shop" className="btn btn-style-two">Top Up Now</Link>
                        </div>
                      </div>
                    </div>
                    <div className="released-game-carousel">
                      <div className="released-game-item">
                        <div className="released-game-img">
                          <img style={{width:'100%'}} src="https://storage.googleapis.com/nero-assets/home/mkt-med-banner.webp" alt="" />
                        </div>
                        <div className="released-game-content">
                          <h4>Idle <span>Market</span></h4>
                          <div className="released-game-list mb-15">
                            <ul className="ul-design">
                              <li><span>Category</span>Idle Game</li>
                              <li><span>Model</span>Idle Games</li>
                              <li><span>Controller</span>Web Base Game</li>
                            </ul>
                          </div>
                          <Link to="/shop" className="btn btn-style-two">Top Up Now</Link>
                        </div>
                      </div>
                    </div>
                    <div className="released-game-carousel">  
                      <div className="released-game-item">
                        <div className="released-game-img">
                          <img style={{width:'100%'}} src="https://storage.googleapis.com/nero-assets/home/jet-med-banner.webp" alt="" />
                        </div>
                        <div className="released-game-content">
                          <h4>Jetwing <span>Ride</span></h4>
                          <div className="released-game-list mb-15">
                            <ul className="ul-design">
                              <li><span>Category</span>Arcade Game</li>
                              <li><span>Model</span>Arcade Game</li>
                              <li><span>Controller</span>Web Base Game</li>
                            </ul>
                          </div>
                          <Link to="/shop" className="btn btn-style-two">Top Up Now</Link>
                        </div>
                      </div>
                    </div>
                    <div className="released-game-carousel">
                      <div className="released-game-item">
                        <div className="released-game-img">
                          <img style={{width:'100%'}} src="https://storage.googleapis.com/nero-assets/home/btc-med-banner.webp" alt="" />
                        </div>
                        <div className="released-game-content">
                          <h4>Bitcoin <span>Millionaire</span></h4>
                          <div className="released-game-list mb-15">
                            <ul className="ul-design">
                              <li><span>Category</span>Idle Game</li>
                              <li><span>Model</span>Idle Games</li>
                              <li><span>Controller</span>Web Base Game</li>
                            </ul>
                          </div>
                          <Link to="/shop" className="btn btn-style-two">Top Up Now</Link>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
                <div className="col-xl-4 col-lg-12">
                  <Slider className="released-game-nav" 
                  asNavFor={nav1}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  ref={slider => (slider2 = slider)} slidesToShow={3} vertical={true} arrows={false} >
                    <div className="released-game-nav-item">
                      <img src="https://storage.googleapis.com/nero-assets/home/sugar-puff-rush-small-banner.webp" alt="sugar puff rush" />
                    </div>
                    <div className="released-game-nav-item">
                      <img src="https://storage.googleapis.com/nero-assets/home/idle-market-small-banner.webp" alt="idle market" />
                    </div>
                    <div className="released-game-nav-item">
                      <img src="https://storage.googleapis.com/nero-assets/home/jetwing-ride-small-banner.webp" alt="jetwing ride" />
                    </div>
                    <div className="released-game-nav-item">
                      <img src="https://storage.googleapis.com/nero-assets/home/bitcoin-mio-small-banner.webp" alt="bitcoin mio" />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </section>
    </section>
    
  );
};

export default GamesArea;
